// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "./Home";
import Profile from "./Profile";
import Login from "./Login";
import ProfessionalForm from "./ProfessionalForm";
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from './AuthContext';
import { DataProvider } from './DataContext';
import LoadingScreen from './LoadingScreen';

function App() {
  return (
    <DataProvider>
      <Router>
        <div>
          {/* Navigation */}
          <nav>
            {/* <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/professionalForm">Form</Link></li>
              <li><Link to="/profile">Profile</Link></li>
            </ul> */}
          </nav> 

          {/* Routing setup */}
          <AuthProvider>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/loading" element={<LoadingScreen />} />
              <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
              <Route path="/professionalForm" element={<PrivateRoute element={<ProfessionalForm />} />} />
            </Routes>
        </AuthProvider>
        </div>
      </Router>
    </DataProvider>
  );
}

export default App;
