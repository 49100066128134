import React, { useState, useEffect } from 'react';
import { fetchAndActivate, getString } from "firebase/remote-config";
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, collection, addDoc, setDoc, DocumentReference, DocumentData, getDoc, GeoPoint, query, where, getDocs, writeBatch} from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import { getDownloadURL, getStorage, ref, uploadBytes, uploadString } from "firebase/storage";
import './ProfessionalForm.css';
import SelectableList from './SelectableList';
import {db, remoteConfig } from './firebase';
import MapComponent from './MapComponent';
import ProfilePictureUpload from './ProfilePictureUpload';
import { Professionals, professionalsConverter } from './Types/Professionals';
import geohash from 'ngeohash';
import { Hairstyle } from './Types/Hairstyle';
import { useData } from './DataContext';
import { Utils } from './Utils';
import Compressor from 'compressorjs';

interface InputField {
  textValue: string;
  numberValue: string;
}

const ProfessionalForm: React.FC = () => {
  const navigate = useNavigate();
  const { prof, setProfessionals, Hairstyles, setHairstyles, customUser, setCustomUser } = useData();
  const [inputs, setInputs] = useState<{ [key: string]: string }>({
    name: prof.name,
    website: prof.link || "",
    tiktok: prof.tiktokHandle || "",
    instagram: prof.instagramHandle || ""
  });
  const [searchValue, setSearchValue] = useState<string>(prof.location || "");
  const defaultGeopoint: GeoPoint = new GeoPoint(0, 0);
  const initialGeopoint: GeoPoint = prof.coordinates 
    ? new GeoPoint(prof.coordinates.latitude, prof.coordinates.longitude)
    : defaultGeopoint;
  const [geopoint, setGeoPoint] = useState<GeoPoint | undefined>(initialGeopoint);
  const [files, setFiles] = useState<File[]>([]);
  const initialInputFields = Hairstyles.map(hairstyle => ({
    textValue: hairstyle.name || '',
    numberValue: hairstyle.price ? hairstyle.price.toString() : ''
}));

  const [inputFields, setInputFields] = useState<InputField[]>(initialInputFields.length ? initialInputFields : [{ textValue: '', numberValue: '' }]);
  const [tags, setTags] = useState<string[]>((remoteConfig.defaultConfig.Tags as string).split(', '));
  const [imageSrc, setImageSrc] = useState<string>('');

   
  // alert(location.state.Name);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
          const reader = new FileReader();
          reader.onload = (e: ProgressEvent<FileReader>) => {
            if(e.target?.result) {
                setImageSrc(e.target.result.toString());
            }
        }        
          reader.readAsDataURL(event.target.files[0]);
      }
  };

  function convertToHairstyles(): Hairstyle[] {
    return inputFields.map(field => 
        new Hairstyle(field.textValue, field.numberValue)
    );
}


  useEffect(() => {
    // Activate and fetch values from Firebase Remote Config
      const fetchData = async () => {
        if(prof && prof.id){
          const storage = getStorage();
          const pathReference = ref(storage, `Professionals/${prof.id}/profilePic.jpg`);
    
          try {
            const url = await getDownloadURL(pathReference);
            setImageSrc(url);
          } catch (error) {
            console.error(error);
          }
        }
      };
    
      fetchData();
    
   
    fetchAndActivate(remoteConfig).then(() => {
      const fetchedTags = getString(remoteConfig, "Tags").split(', ');
      setTags(fetchedTags);
    }).catch((error) => {
      console.error('Error fetching remote config:', error);
      // If there's an error, the component will still use the default JSON values.
    });
  }, []);

  const genders = ['Male', 'Female', 'Non-Binary'];
  const hairtypes = ['Type 1', 'Type 2A', 'Type 2B', 'Type 2C', 
  'Type 3A', 'Type 3B', 'Type 3C', 'Type 4A', 'Type 4B', 'Type 4C'];
  const itemsList = ['Item 1', 'Item 2', 'Item 3', 'Item 4'];
  const [defaultGenders, setDefaultGenders] = useState<Set<string>>(new Set(prof.gendersServed));
  const [defaultHairtypes, setDefaultHairtypes] = useState<Set<string>>(new Set(prof.hairtypesServed));
  const [defaultTags, setDefaultTags] = useState<Set<string>>(new Set(prof.tags));

  

  const handleInputChange = (index: number, type: 'text' | 'number', event: React.ChangeEvent<HTMLInputElement>) => {
    const values = [...inputFields];
    if (type === 'text') {
        values[index].textValue = event.target.value;
    } else if (type === 'number') {
        values[index].numberValue = event.target.value;
    }
    setInputFields(values);
};



const handleAddField = () => {
  setInputFields([...inputFields, { textValue: '', numberValue: '' }]);
};

const storage = getStorage();

// Create a storage reference
const createProfilePicRef = (id: string) => {
  const path = `Professionals/${id}/profilePic.jpg`;
  return ref(storage, path);
};

  const handleRemoveField = (index: number) => {
    const values = [...inputFields];
    let removedFields = values.splice(index, 1);

    setInputFields(values);
  };
  

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  function compressAndUploadImage(file: File, professionalsDoc: DocumentReference<Professionals, DocumentData>): Promise<any> {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.2,
        success(compressedFile) {
          const newUuid = uuidv4();
          const currentPicPath = `Professionals/${professionalsDoc.id}/Gallery/${newUuid}.jpg`;
          const fileRef = ref(storage, currentPicPath);
  
          uploadBytes(fileRef, compressedFile).then(() => {
            resolve(currentPicPath);
          }).catch(reject);
        },
        error(err) {
          reject(err);
        },
      });
  })
}
  
    
// Iterate over the hairstyles and add them to Firestore:
const updateHairstylesInFirestore = async ( professionalId: string, hairstyles: Hairstyle[], formerHairstyleNames?: Set<string>) => {
  const professionalDocRef = doc(db, 'Professionals', professionalId);
  const hairstylesCollectionRef = collection(professionalDocRef, 'Hairstyles');
    try {
        for (const hairstyle of hairstyles) {
            formerHairstyleNames?.delete(hairstyle.name);
            const hairstyleDocRef = doc(hairstylesCollectionRef, hairstyle.name.toLowerCase());

            await setDoc(hairstyleDocRef, {
                ...hairstyle, 
            });
        }
    } catch (error) {
        console.error('Error adding hairstyles:', error);
    }

    if(formerHairstyleNames?.size !== 0 ){
      const batch = writeBatch(db);
// Use Promise.all to get all documents in parallel
const lowercaseFormerNames = Array.from(formerHairstyleNames!).map(name => name.toLowerCase());
const docsFetchPromises = lowercaseFormerNames.map(id => getDoc(doc(hairstylesCollectionRef, id)));

Promise.all(docsFetchPromises).then(docSnapshots => {
  docSnapshots.forEach(docSnapshot => {
    if (docSnapshot.exists()) {
      batch.delete(docSnapshot.ref);
    }
  });
  
  return batch.commit();
}).catch(error => {
  console.error("Error deleting documents: ", error);
});
    }
  }


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let localStrings = [];  // Local variable
    let flag = true;

    if(imageSrc === ''){
      localStrings.push("profile picture");
      flag = false;
    }
    if(inputs.name === "" || inputs.name === undefined){
      localStrings.push("name");
      flag = false;
    }
    if(geopoint === undefined || (geopoint.latitude === 0 && geopoint.longitude === 0)){
      localStrings.push("coordinates");
      flag = false;
    }
    if(searchValue === "" || searchValue === undefined){
      localStrings.push("address");
      flag = false;
    }
    if(defaultGenders.size === 0){
      localStrings.push("genders");
      flag = false;
    }
    if(defaultHairtypes.size === 0){
      localStrings.push("hairtypes");
      flag = false;
    }
    if(defaultTags.size === 0){
      localStrings.push("tags");
      flag = false;
    }
    if(inputFields.length === 0){
      localStrings.push("hairstyles");
      flag = false;
    }

    if(flag && geopoint !== undefined){
      let profId = customUser.Accounts?.["Professional"];

      let professionalsDoc: DocumentReference<Professionals, DocumentData>;
   
      if (profId){
        professionalsDoc = doc(db, "Professionals", profId!).withConverter(professionalsConverter);
        const profSnap = await getDoc(professionalsDoc);
        if(profSnap.exists()){
          let currentProf = profSnap.data();
          if(imageSrc !== '' && Utils.isDataURL(imageSrc)){
            const storageRef = createProfilePicRef(professionalsDoc.id);
            try {
              uploadString(storageRef, imageSrc, 'data_url').then((snapshot) => {
              });
              } catch(error){
                return;
            }
          }
          
          let uploadPromises: Promise<string>[] = [];
          if(files.length !== 0 ){
              uploadPromises = files.map((file) => {
                 return compressAndUploadImage(file, professionalsDoc);
            });
          }

          try {
            const galleryPicPaths = await Promise.all(uploadPromises);
            const formerHairstyleNames = currentProf.hairstyleNames;
            currentProf.updateProfessional({
              name: inputs.name,
              coordinates: geopoint!,
              geohash: geohash.encode(geopoint!.latitude, geopoint!.longitude),
              gendersServed: [...defaultGenders],
              tags: [...defaultTags],
              hairstyleNames: inputFields.map(field => field.textValue),
              hairtypesServed: [...defaultHairtypes],
              galleryPicPaths: galleryPicPaths
          });
          setProfessionals(currentProf);
          await setDoc(professionalsDoc, currentProf);
          const hairstyles: Hairstyle[] = convertToHairstyles();
          setHairstyles(hairstyles);
          updateHairstylesInFirestore(profId, hairstyles, new Set(formerHairstyleNames));
          } catch (error) {
        }

        }       
      } else if(customUser.id) {
        professionalsDoc = doc(db, "Professionals", customUser.id).withConverter(professionalsConverter);
        if(imageSrc !== '' && Utils.isDataURL(imageSrc)){
          const storageRef = createProfilePicRef(professionalsDoc.id);
          try {
            uploadString(storageRef, imageSrc, 'data_url').then((snapshot) => {
            });
            } catch(error){
              return;
          }
        }
        
        let uploadPromises: Promise<string>[] = [];
        if(files.length !== 0 ){
            uploadPromises = files.map((file) => {
               return compressAndUploadImage(file, professionalsDoc);
          });
        }
        try {
          const galleryPicPaths = await Promise.all(uploadPromises);
          const professionals: Professionals =  new Professionals(
            inputs.name,
            professionalsDoc.id,
            5,
            5,
            1,
            5,
            0,
            geopoint!,
            geohash.encode(geopoint!.latitude, geopoint!.longitude),
            [...defaultGenders],
            [...defaultTags],
            inputFields.map(field => field.textValue),
            [...defaultHairtypes],
            galleryPicPaths
          );
        setProfessionals(professionals);
        setDoc(professionalsDoc, professionals);
        const hairstyles: Hairstyle[] = convertToHairstyles();
        setHairstyles(hairstyles);
        const userRef = doc(db, 'Users', customUser.id);
        customUser.Accounts = {"Professional": customUser.id}
        setDoc(userRef, customUser);
        updateHairstylesInFirestore(customUser.id, hairstyles);
        } catch (error) {
      }
      }
      else {
        navigate('/profile');
      }

      
   
  navigate('/profile');   
    
    } else {// This will reflect all the changes
      const alerts = localStrings.join(' '); // Assuming concatenateStrings joins the array
      alert(alerts);
    }
    
}


const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  if (e.target.files) {
      // Combine the existing files with the newly selected files
      setFiles([...files, ...Array.from(e.target.files)]);
  }
};


  const removeImage = (indexToRemove: number) => {
    setFiles(files.filter((_, index) => index !== indexToRemove));
  };


  return (
    <div className="form-container">
      <h1>Professional Signup</h1>
      <div className="profilePic">
            <ProfilePictureUpload imageSrc={imageSrc} handleImageChange={handleImageChange}/>
        </div>
      <form id="profForm" onSubmit={handleSubmit}>
      <label>
      <input 
        className="bio-field"
        type="text" 
        name="name" 
        value={inputs.name} 
        onChange={handleChange}
        placeholder="Name"
      />
      </label>
      <label>
        < MapComponent searchValue={searchValue} setSearchValue={setSearchValue} geopoint={geopoint} setGeoPoint={setGeoPoint}/>
      </label> 
        <h2>Links - optional</h2>
        <label>
      <input 
        className="bio-field"
        type="text" 
        name="website" 
        value={inputs.website} 
        onChange={handleChange}
        placeholder="Website"
      />
      </label>
      <label>
        <input
          className="bio-field" 
          type="text" 
          name="tiktok" 
          value={inputs.tiktok} 
          onChange={handleChange}
          placeholder="Tiktok Handle"
        />
        </label><label>
      <input 
        className="bio-field"
        type="text" 
        name="instagram" 
        value={inputs.instagram} 
        onChange={handleChange}
        placeholder="Instagram Handle"
      />
      </label>
      <h2>About</h2>
      <p className="dropdown-title">Genders</p>
      <SelectableList items={genders} selectedSet={defaultGenders} onToggleItem={setDefaultGenders}/>
      <p className="dropdown-title">Hairtypes Served</p>
      <SelectableList items={hairtypes} selectedSet={defaultHairtypes} onToggleItem={setDefaultHairtypes}/>
      <p className="dropdown-title">Tags</p>
      <SelectableList items={tags} selectedSet={defaultTags} onToggleItem={setDefaultTags}/>
      <p className="dropdown-title">Hairstyles</p>
      {inputFields.map((inputField, index) => (
    <div key={index}>
        <input
            type="text"
            value={inputField.textValue}
            onChange={(event) => handleInputChange(index, 'text', event)}
            className="inputField"
            placeholder="Hairstyle Name"
        />
        <input
            type="number"
            value={inputField.numberValue}
            onChange={(event) => handleInputChange(index, 'number', event)}
            className="inputField"
            placeholder="Hairstyle Price"
        />
        <button className="remove-hairstyle-Button"
        type="button" onClick={() => handleRemoveField(index)}>
            Remove
        </button>
    </div>
))}

      <button className="add-hairstyle-Button" type="button" onClick={handleAddField}>
        Add Hairstyle
      </button>
      <br />
      <p className="dropdown-title">Gallery</p>
      <div>
      <label className="customFileUpload">
    Upload File
    <input type="file" onChange={onFileChange} accept="image/*" multiple style={{ display: 'none' }} />
</label>

      <div className="imageContainer">
        {files.map((file, index) => (
          <div key={index} className="imageWrapper">
            <img src={URL.createObjectURL(file)} alt="Uploaded Preview" width="100" />
            <button className="deleteButton" onClick={() => removeImage(index)}>x</button>
          </div>
        ))}
      </div>
    </div>
      <button className="hairstyle-submit-button"
      type="submit">Submit</button>
    </form>
    </div>
  )
}

export default ProfessionalForm;
