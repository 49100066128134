import firebase from 'firebase/compat/app';
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";

export class User {
    Name?: string;
    id?: string; // Keeping this lowercase as requested.
    Gender?: string;
    Accounts?: { [key: string]: string };
    QueerFriendly?: string;
    Race?: string;
    Reviews?: { [key: string]: string };
    FavoriteProfessionals: { [key: string]: string } = {};
    Hairtype?: string;
    Tags?: string[];
    OpenRequests: boolean = false;

    constructor(
        Name?: string,
        id?: string,
        Gender?: string,
        Accounts?: { [key: string]: string },
        QueerFriendly?: string,
        Race?: string,
        Reviews?: { [key: string]: string },
        FavoriteProfessionals?: { [key: string]: string },
        Hairtype?: string,
        Tags?: string[],
        OpenRequests: boolean = false
    ) {
        this.Name = Name;
        this.id = id;
        this.Gender = Gender;
        this.Accounts = Accounts;
        this.QueerFriendly = QueerFriendly;
        this.Race = Race;
        this.Reviews = Reviews;
        this.FavoriteProfessionals = FavoriteProfessionals || {};
        this.Hairtype = Hairtype;
        this.Tags = Tags;
        this.OpenRequests = OpenRequests;
    }
}

export const UserConverter: FirestoreDataConverter<User, DocumentData> = {
    toFirestore(user: User): firebase.firestore.DocumentData {
        const definedFields: Partial<User> = {};

        if (user.Name !== undefined) definedFields.Name = user.Name;
        if (user.id !== undefined) definedFields.id = user.id;
        if (user.Gender !== undefined) definedFields.Gender = user.Gender;
        if (user.Accounts !== undefined) definedFields.Accounts = user.Accounts;
        if (user.QueerFriendly !== undefined) definedFields.QueerFriendly = user.QueerFriendly;
        if (user.Race !== undefined) definedFields.Race = user.Race;
        if (user.Reviews !== undefined) definedFields.Reviews = user.Reviews;
        if (user.FavoriteProfessionals !== undefined) definedFields.FavoriteProfessionals = user.FavoriteProfessionals;
        if (user.Hairtype !== undefined) definedFields.Hairtype = user.Hairtype;
        if (user.Tags !== undefined) definedFields.Tags = user.Tags;
        if (user.OpenRequests !== undefined) definedFields.OpenRequests = user.OpenRequests;
    
        return definedFields;
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: SnapshotOptions
      ): User {
        const data = snapshot.data(options) as any;
        return new User(
          data.Name,
          data.id,
          data.Gender,
          data.Accounts,
          data.QueerFriendly,
          data.Race,
          data.Reviews,
          data.FavoriteProfessionals,
          data.Hairtype,
          data.Tags,
          data.OpenRequests
        );
      }
}
