import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useData } from '../DataContext';
import './ProfSignUp.css';

function ProfSignUp() {
    const navigate = useNavigate();

    const { prof } = useData();

    const handleSignUpClick = () => {
      if (prof) {
        navigate('/profile');
      } else {
        navigate('/professionalForm');
      }
    };
    
    return(
        <div className='profSignUp'>
            <h1>For Professionals</h1>
            <p>Now onboarding our first professionals</p>
            <button className="signup-button"
             onClick={(handleSignUpClick)}>Sign up here
            </button>
        </div>
    )
}

export default ProfSignUp
