// Profile.js
import React, { useState, useEffect } from 'react';
import { GeoPoint } from "firebase/firestore"; 
import { useNavigate } from 'react-router-dom';
import { Professionals } from './Types/Professionals';
import { Hairstyle } from './Types/Hairstyle';
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import {auth} from './firebase';
import './Profile.css';
import { useData } from './DataContext';
import { AuthProvider, useAuth } from './AuthContext';
import CachedImage from './ImageRows/CachedImage';
 
interface ProfilePageProps {
  professional: Professionals;
  hairstyles: Hairstyle[];
}

const Profile: React.FC<ProfilePageProps> = () => {

  const navigate = useNavigate();

  const { prof, Hairstyles } = useData();

  const { signOut } = useAuth();

  const [urlString, setUrlString] = useState('');

  useEffect(() => {
    if(prof && prof.id){
      const storage = getStorage();
      const pathReference = ref(storage, `Professionals/${prof.id}/profilePic.jpg`);

      getDownloadURL(pathReference)
        .then((url) => {
          setUrlString(url);
        })
        .catch((error) => {
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              break;
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;
            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              break;
            default:
              // Handle any other errors
              console.error(error);
              break;
          }
        });
    } else {
      setUrlString('/keepers-02.jpg');
    }
  }, [prof.id]);

  return (
    <div className="profile-page">
      <div className="profile-page-inner">
      <div className="center-container">
  <h1>{prof.name}</h1>
  <img src={urlString} alt={`${prof.name}'s profile picture`} onError={(e: React.BaseSyntheticEvent) => {
    e.target.src = '/keepers-02.jpg';
  }} />
  <strong><p>Location: {prof.location}</p></strong>
  <div className="profile-nav-buttons">
  <button onClick={() => navigate('/professionalForm')}>Edit Profile</button>
  <button onClick={signOut}>Sign Out</button>
    
  </div>
</div>

      <div className="info">
      <p className="info-row">
  <strong>Website:</strong>
  <span className="info-value">
  {prof.link ? 
      <a href={prof.link.startsWith('http://') || prof.link.startsWith('https://') ? 
          prof.link : 
          `http://${prof.link}`} 
          target="_blank" 
          rel="noopener noreferrer">
        {prof.link}
      </a> 
      : 'N/A'}
  </span>
</p>
  <p className="info-row">
    <strong>TikTok:</strong>
    {prof.tiktokHandle ? (
      <a href={`https://www.tiktok.com/@${prof.tiktokHandle}`} 
        target="_blank" 
        rel="noopener noreferrer"
        className="info-value">
        @{prof.tiktokHandle}
      </a>
    ) : (
      <span className="info-value">N/A</span>
    )}
  </p>
    <p className="info-row">
    <strong>Instagram:</strong>
    {prof.instagramHandle ? (
      <a href={`https://www.instagram.com/${prof.instagramHandle}`} 
        target="_blank" 
        rel="noopener noreferrer"
        className="info-value">
        @{prof.instagramHandle}
      </a>
    ) : (
      <span className="info-value">N/A</span>
    )}
  </p>
    <p className="info-row"><strong>Genders Served:</strong> {prof.gendersServed.map((gender, index) => (
        <span key={index} className="info-value">{gender}</span>
    ))}</p>
    <p className="info-row"><strong>Hairtypes Served:</strong> {prof.hairtypesServed.map((hairtype, index) => (
        <span key={index} className="info-value">{hairtype}</span>
    ))}</p>
    <p className="info-row"><strong>Tags:</strong> {prof.tags.map((tag, index) => (
        <span key={index} className="info-value">{tag}</span>
    ))}</p>
</div>

      <div className="hairstyles">
        <h2>Hairstyles</h2>
        {Hairstyles.map((hairstyle, index) => (
      <div key={index} className="hairstyle-item">
          <p>{hairstyle.name}: ${hairstyle.price}</p>
      </div>
      ))}
      </div>
      <div className="gallery">
        <h2>Gallery</h2>
        {prof.galleryPicPaths.map(path => (
          <CachedImage key={path} firebaseFileLocation={path} />
        ))}
      </div>
    </div>
      </div>
      
  );
};

export default Profile;
