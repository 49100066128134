import React, { useState } from 'react';
import {db} from '../firebase';
import { addDoc, collection } from "firebase/firestore";
import './Newsletter.css'

function Newsletter() {

    const [email, setEmail] = useState("")

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const submitHandler = (e) => {
        e.preventDefault()
        if (email) {
            try {
                const docRef = addDoc(collection(db, "NewsletterEmails"), {
                  email: email
                });
              } catch (e) {
              }

        }
    }

    return (
        <div className="newsletter">
            <h1>For Clients</h1>
            <p>Subscribe to our newsletter for updates</p>
            <div className="signupForm">
                <form onSubmit={submitHandler}>
                    <input
                        className="input-field"
                        type='email'
                        placeholder='Your Email'
                        onChange={handleEmailChange}
                        value={email}
                        />
                    <button className="submit-button">SUBSCRIBE</button>
                </form>
            </div>
        </div>
    )
}

export default Newsletter
