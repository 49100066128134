import React, { useState } from 'react';
import ImageRow from './ImageRow';
import './ImageRows.css'


const ImageRows = ({imagePaths, title, allTags, allTitles}) => {
  return(
    <div className="imageRows">
    <div className="Title">{title}</div>
    {imagePaths.map((imagePath, index) => {
       return <ImageRow key={index.toString()} imagePath={imagePath} alt={index} tags={allTags[index]} title={allTitles[index]}/>;
     })}
    </div>
  )
}

export default ImageRows
