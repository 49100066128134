// DataContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';
import { GeoPoint } from "firebase/firestore"; 
import { Professionals } from './Types/Professionals';
import { Hairstyle } from './Types/Hairstyle';
import { User as CustomUser } from './Types/User';


interface DataProviderProps {
  children: ReactNode;
}

interface DataContextProps {
  prof: Professionals;
  setProfessionals: React.Dispatch<React.SetStateAction<Professionals>>;
  Hairstyles: Hairstyle[];
  setHairstyles: React.Dispatch<React.SetStateAction<Hairstyle[]>>;
  customUser: CustomUser;
  setCustomUser: React.Dispatch<React.SetStateAction<CustomUser>>;
}

export const DataContext = createContext<DataContextProps | null>(null);

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {

  const emptyProfessionals = new Professionals(
    '',                              // name
    '',                              // id
    5,                               // communication
    5,                               // efficiency
    1,                               // pricing
    5,                               // comfort
    0,                               // numberOfReviews
    new GeoPoint(0, 0),              // coordinates
    '',                              // geohash
    [],                              // gendersServed
    [],                              // tags
    [],                              // hairstyleNames
    [],                              // hairtypesServed
    []                               // galleryPicPaths
    // Note: Optional fields will be undefined by default and don't need to be explicitly set.
);

  const emptyUser = new CustomUser();
  const [prof, setProfessionals] = useState(emptyProfessionals);
  const [Hairstyles, setHairstyles] = useState<Hairstyle[]>([]);
  const [customUser, setCustomUser] = useState(emptyUser);

  const value = {
    prof,
    setProfessionals,
    Hairstyles,
    setHairstyles,
    customUser,
    setCustomUser
  };

  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};
