import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { LazyLoadImage } from "react-lazy-load-image-component";

import './ProfilePictureUpload.css'

function ProfilePictureUpload({imageSrc, handleImageChange}) {
    
    
    return (
        <div className="profile-picture" onClick={() => document.getElementById('fileInput').click()}>
            {/* <LazyLoadImage src={imageSrc} alt="Profile"/> */}
            {imageSrc && <img src={imageSrc} alt="Profile" />}
            <div className="overlay">
                <FontAwesomeIcon icon={faCamera} size="2x" />
                <p>Click to upload</p>
            </div>
            <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleImageChange}  accept="image/*" />
        </div>
    );
}

export default ProfilePictureUpload;
