import firebase from 'firebase/compat/app';
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";

export class Hairstyle {
    name: string;
    price: string;
  
    constructor(name: string = '', price: string = '') {
      this.name = name;
      this.price = price;
    }
  
    isValidHairstyle(): boolean {
      return this.name !== "" && this.price !== "";
    }
  }

  export const HairstyleConverter: FirestoreDataConverter<Hairstyle, DocumentData> = {
    toFirestore(hairstyle: Hairstyle): firebase.firestore.DocumentData {
      return {
        name: hairstyle.name,
        price: hairstyle.price
      };
    }, 
    fromFirestore(
      snapshot: QueryDocumentSnapshot<firebase.firestore.DocumentData>,
      options: SnapshotOptions
    ) : Hairstyle {
      const data = snapshot.data(options) as any;
      return new Hairstyle(data.name, data.price);
    }
  };
  