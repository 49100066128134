import firebase from 'firebase/compat/app';
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions, GeoPoint } from "firebase/firestore";
  
  export class Professionals {
    name: string;
    id: string;
    communication: number;
    efficiency: number;
    pricing: number;
    comfort: number;
    numberOfReviews: number;
    coordinates: GeoPoint;
    geohash: string;
    gendersServed: string[];
    tags: string[];
    hairstyleNames: string[];
    hairtypesServed: string[];
    galleryPicPaths: string[];
    location?: string;
    tiktokHandle?: string;
    instagramHandle?: string;
    link?: string;
    profilePicPath?: string;

    constructor(
      name: string,
      id: string,
      communication: number,
      efficiency: number,
      pricing: number,
      comfort: number,
      numberOfReviews: number,
      coordinates: GeoPoint,
      geohash: string,
      gendersServed: string[],
      tags: string[],
      hairstyleNames: string[],
      hairtypesServed: string[],
      galleryPicPaths: string[],
      location?: string,
      tiktokHandle?: string,
      instagramHandle?: string,
      link?: string,
      profilePicPath?: string
    ) {
        this.name = name;
        this.id = id;
        this.communication = communication;
        this.efficiency = efficiency;
        this.pricing = pricing;
        this.comfort = comfort;
        this.numberOfReviews = numberOfReviews;
        this.coordinates = coordinates;
        this.geohash = geohash;
        this.gendersServed = gendersServed;
        this.tags = tags;
        this.hairstyleNames = hairstyleNames;
        this.hairtypesServed = hairtypesServed;
        this.galleryPicPaths = galleryPicPaths;
        this.location = location;
        this.tiktokHandle = tiktokHandle;
        this.instagramHandle = instagramHandle;
        this.link = link;
        this.profilePicPath = profilePicPath;
    } 
    
    updateProfessional(details: Partial<Professionals>) {
        if (details.name !== undefined) this.name = details.name;
        if (details.id !== undefined) this.id = details.id;
        if (details.communication !== undefined) this.communication = details.communication;
        if (details.efficiency !== undefined) this.efficiency = details.efficiency;
        if (details.pricing !== undefined) this.pricing = details.pricing;
        if (details.comfort !== undefined) this.comfort = details.comfort;
        if (details.numberOfReviews !== undefined) this.numberOfReviews = details.numberOfReviews;
        if (details.coordinates !== undefined) this.coordinates = details.coordinates;
        if (details.geohash !== undefined) this.geohash = details.geohash;
        if (details.gendersServed) this.gendersServed = [...details.gendersServed];
        if (details.tags) this.tags = [...details.tags];
        if (details.hairstyleNames) this.hairstyleNames = [...details.hairstyleNames];
        if (details.hairtypesServed) this.hairtypesServed = [...details.hairtypesServed];

        //Combine with old gallery pictures
        if (details.galleryPicPaths) this.galleryPicPaths = [...this.galleryPicPaths, ...details.galleryPicPaths];

        if (details.location !== undefined) this.location = details.location;
        if (details.tiktokHandle !== undefined) this.tiktokHandle = details.tiktokHandle;
        if (details.instagramHandle !== undefined) this.instagramHandle = details.instagramHandle;
        if (details.link !== undefined) this.link = details.link;
        if (details.profilePicPath !== undefined) this.profilePicPath = details.profilePicPath;
    }
  
  
}

export const professionalsConverter:  FirestoreDataConverter<Professionals, DocumentData>  = {
  toFirestore(professional: Professionals): firebase.firestore.DocumentData {
    return {
      name: professional.name,
      id: professional.id,
      communication: professional.communication,
      efficiency: professional.efficiency,
      pricing: professional.pricing,
      comfort: professional.comfort,
      numberOfReviews: professional.numberOfReviews,
      coordinates: professional.coordinates,
      geohash: professional.geohash,
      gendersServed: professional.gendersServed,
      tags: professional.tags,
      hairstyleNames: professional.hairstyleNames,
      hairtypesServed: professional.hairtypesServed,
      galleryPicPaths: professional.galleryPicPaths,
      location: professional.location,
      tiktokHandle: professional.tiktokHandle,
      instagramHandle: professional.instagramHandle,
      link: professional.link
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<firebase.firestore.DocumentData>,
    options: SnapshotOptions
  ): Professionals {
    const data = snapshot.data(options) as any;
    return new Professionals(
      data.name,
      data.id,
      data.communication,
      data.efficiency,
      data.pricing,
      data.comfort,
      data.numberOfReviews,
      data.coordinates,
      data.geohash,
      data.gendersServed,
      data.tags,
      data.hairstyleNames,
      data.hairtypesServed,
      data.galleryPicPaths,
      data.location,
      data.tiktokHandle,
      data.instagramHandle,
      data.link
    );
  }
};

