// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getRemoteConfig } from "firebase/remote-config";
import { getAuth } from "firebase/auth";  // Import for Firebase Authentication
import { getStorage } from "firebase/storage";

// Your existing Firebase configuration object
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);

// Get a Firestore instance
const db = getFirestore(firebase);

// Get a Firebase Remote Config instance
const remoteConfig = getRemoteConfig(firebase);

// Set remote config defaults and settings
let rcDefaults = require('./remote_config_defaults.json');
remoteConfig.defaultConfig = rcDefaults;
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

// Get a Firebase Auth instance
const auth = getAuth(firebase);  // Initialize Firebase Authentication

const storage = getStorage(firebase);
// Export instances
export { db, firebase, remoteConfig, auth, storage };  // Export Firebase Auth instance
