import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { User as FirebaseUser, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, User, onAuthStateChanged, signInWithEmailAndPassword, signOut, setPersistence, browserLocalPersistence } from "firebase/auth";
import { auth, db } from './firebase';
import { collection, doc, getDoc, setDoc, getDocs, GeoPoint } from '@firebase/firestore';
import { Professionals, professionalsConverter } from './Types/Professionals';
import { Hairstyle } from './Types/Hairstyle';
import { DataProvider, useData } from './DataContext';
import { json, useLocation, useNavigate } from 'react-router-dom';
import { User as CustomUser, UserConverter } from './Types/User';


interface AuthContextProps {
  user: User | null;
  loading: boolean;
  loginWithGoogle: () => Promise<void>;
  loginWithEmail: (email: string, password: string) => Promise<void>;
  signUpWithEmail: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextProps | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

const LOCAL_STORAGE_KEY_USER = 'firebaseUser';
const LOCAL_STORAGE_KEY_CUSTOM_USER = 'customUser';
const LOCAL_STORAGE_KEY_PROFESSIONAL = 'professionalData';
const LOCAL_STORAGE_KEY_HAIRSTYLES = 'hairstylesData';


const storeUserInLocalStorage = (user: User) => {
  localStorage.setItem(LOCAL_STORAGE_KEY_USER, JSON.stringify(user));
};

const storeProfessionalInLocalStorage = (professional: Professionals) => {
  localStorage.setItem(LOCAL_STORAGE_KEY_PROFESSIONAL, JSON.stringify(professional));
};

const storeHairstylesInLocalStorage = (hairstyles: Hairstyle[]) => {
  localStorage.setItem(LOCAL_STORAGE_KEY_HAIRSTYLES, JSON.stringify(hairstyles));
};

const storeCustomUserInLocalStorage = (CustomUser: CustomUser) => {
  localStorage.setItem(LOCAL_STORAGE_KEY_CUSTOM_USER, JSON.stringify(CustomUser));
};

const fetchUserFromLocalStorage = (): User | null => {
  const storedUser = localStorage.getItem(LOCAL_STORAGE_KEY_USER);
  return storedUser ? JSON.parse(storedUser) : null;
};

const fetchProfessionalFromLocalStorage = (): Professionals| null => {
  const storedProfessional = localStorage.getItem(LOCAL_STORAGE_KEY_PROFESSIONAL);
  return storedProfessional ? JSON.parse(storedProfessional) : null;
};

const fetchCustomUserFromLocalStorage = (): CustomUser | null => {
  const storedCustomUser = localStorage.getItem(LOCAL_STORAGE_KEY_CUSTOM_USER);
  return storedCustomUser ? JSON.parse(storedCustomUser) : null;
};

const fetchHairstylesFromLocalStorage = (): Hairstyle[] | null => {
  const storedHairstyles = localStorage.getItem(LOCAL_STORAGE_KEY_HAIRSTYLES);
  return storedHairstyles ? JSON.parse(storedHairstyles) : null;
};


const clearAllDataFromLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY_USER);
  localStorage.removeItem(LOCAL_STORAGE_KEY_CUSTOM_USER);
  localStorage.removeItem(LOCAL_STORAGE_KEY_PROFESSIONAL);
  localStorage.removeItem(LOCAL_STORAGE_KEY_HAIRSTYLES);
};


export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();
  const location = useLocation();

  const {prof, setProfessionals, Hairstyles, setHairstyles, customUser, setCustomUser} = useData();

  const handleUserLogin = async (user: FirebaseUser) => {
    setUser(user);
    storeUserInLocalStorage(user);
    const userRef = doc(db, "Users", user.uid).withConverter(UserConverter);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const user = docSnap.data();
      setCustomUser(user);
      storeCustomUserInLocalStorage(user);
      if (user.Accounts !== undefined && 'Professional' in user.Accounts) {
        const profRef = doc(db, "Professionals", user.Accounts['Professional']).withConverter(professionalsConverter);
        const querySnapshot = await getDocs(collection(db, 'Professionals', user.Accounts['Professional'], 'Hairstyles'));
        const hairstyles: Hairstyle[] = [];
        querySnapshot.forEach((doc) => {
          const hairstyle = doc.data() as Hairstyle;
          hairstyles.push(hairstyle);
        });

        const profSnap = await getDoc(profRef);
        if (profSnap.exists()) {
          const prof = profSnap.data();
          setProfessionals(prof);
          setHairstyles(hairstyles);
          storeProfessionalInLocalStorage(prof);
          storeHairstylesInLocalStorage(hairstyles);
          if (location && location.pathname != '/login') {
            navigate(location);
          } else {
            setLoading(false);
            navigate('/profile');
          }
        } else {
          setLoading(false);
          if (location && location.pathname != '/login') {
            navigate(location);
          } else {
            navigate('/profile');
          }
        }
      } else { 
        setLoading(false);
        navigate('/professionalForm');
      }
    } else {;
        let alteredUser = customUser;
        alteredUser.id = user.uid;
        setCustomUser(alteredUser);
        setDoc(userRef, alteredUser);
        navigate('/professionalForm');
    }
};


  //... (Rest of your functions and methods, including handleUserLogin)
  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
        .then(async (result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            if (credential === undefined) {
                return;
            }
            const token = credential!.accessToken;
            const user = result.user;
            await handleUserLogin(user);
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.customData.email;
            const credential = GoogleAuthProvider.credentialFromError(error);
            throw error; 
        });
};

const signUpWithEmail = async (email: string, password: string) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await handleUserLogin(user);
    } catch (error) {
        console.error(error);
    }
};

const loginWithEmail = async (email: string, password: string) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        await handleUserLogin(user);
    } catch (error: any) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
    }
};


useEffect(() => {
  // Fetch user from local storage
  const storedUser = fetchUserFromLocalStorage();
  const storedProfessional = fetchProfessionalFromLocalStorage();
  const storedHairstyles = fetchHairstylesFromLocalStorage();
  const storedCustomUser = fetchCustomUserFromLocalStorage();
  if (storedUser) {
      setUser(storedUser);
      setLoading(false);
      if(storedProfessional){
        setProfessionals(storedProfessional);
      }
      
      if(storedHairstyles){
        setHairstyles(storedHairstyles);
      }

      if(storedCustomUser){
        setCustomUser(storedCustomUser);
      }

      if(storedHairstyles || storedProfessional){
        if (location && location.pathname != '/login') {
          navigate(location);
        } else {
          navigate('/profile');
        }
        return;
      }
      handleUserLogin(storedUser);
  } else{
    setLoading(false);
  }

  // If no user in local storage, then check Firebase auth state
  let unsubscribe: any;  // To hold the unsubscribe function from onAuthStateChanged

  setPersistence(auth, browserLocalPersistence)
    .then(() => {
      unsubscribe = onAuthStateChanged(auth, user => {
        setUser(user);
        setLoading(false);
      });
    })
    .catch(error => {
      console.error('Failed to set auth persistence:', error);
    });

  // Cleanup: unsubscribe from onAuthStateChanged if it was set
  return () => {
    if (unsubscribe) {
      unsubscribe();
      setLoading(false);
    }
  };
}, [auth]);

useEffect(() => {
  if (user) {
    storeUserInLocalStorage(user);
    setLoading(false);
  } else {
    localStorage.removeItem(LOCAL_STORAGE_KEY_USER);
  }
}, [user]);

// Effect for storing professionals changes
useEffect(() => {
  if (prof) {
    storeProfessionalInLocalStorage(prof);
    setLoading(false);
  } else {
  localStorage.removeItem(LOCAL_STORAGE_KEY_PROFESSIONAL);
  }
}, [prof]);

// Effect for storing hairstyles changes
useEffect(() => {
  if (Hairstyles) {
    storeHairstylesInLocalStorage(Hairstyles)
    setLoading(false);
  } else {
    localStorage.removeItem(LOCAL_STORAGE_KEY_HAIRSTYLES);
  }
}, [Hairstyles]);

// Effect for storing custom user changes
useEffect(() => {
  if (customUser) {
    storeCustomUserInLocalStorage(customUser);
    setLoading(false);
  } else {
    localStorage.removeItem(LOCAL_STORAGE_KEY_CUSTOM_USER);
  }
}, [customUser]);

  //... (Rest of your login and signout methods)

  const handleSignOut = async () => {
    try {
      const emptyProfessionals = new Professionals(
        '',                              // name
        '',                              // id
        5,                               // communication
        5,                               // efficiency
        1,                               // pricing
        5,                               // comfort
        0,                               // numberOfReviews
        new GeoPoint(0, 0),              // coordinates
        '',                              // geohash
        [],                              // gendersServed
        [],                              // tags
        [],                              // hairstyleNames
        [],                              // hairtypesServed
        []                               // galleryPicPaths
        // Note: Optional fields will be undefined by default and don't need to be explicitly set.
    );
      await signOut(auth);
      setUser(null);
      setCustomUser(new CustomUser());
      setProfessionals(emptyProfessionals);
      setHairstyles([]);
      clearAllDataFromLocalStorage();
      navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const value = {
    user,
    loading,
    loginWithGoogle,
    loginWithEmail,
    signUpWithEmail,
    signOut: handleSignOut 
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
