import React, { useState } from 'react';
import './ImageRow.css'


const ImageRow = ({imagePath, alt, tags, title}) => {
  return(
    <div className="imageRow">
    <div className="imageRowDescription">
    {tags.map((tag) => (
        <h1 key={tag.toString()}>{tag}</h1>
      ))}
    </div>
    <h3>{title}</h3>
    <img src={imagePath} alt={alt}/>
    </div>
  )
}

export default ImageRow
