import React, { useEffect, useState } from 'react';
import './Login.css';
// In your Login component
import { useAuth } from './AuthContext';
 
const Login = () => {

    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [rePassword, setRePassword] = useState<string>('');
    const [showPageA, setShowPageA] = useState(true);

    const { loginWithGoogle, loginWithEmail, signUpWithEmail } = useAuth();  

    useEffect(() => {
      return () => {
        setErrorMessage('');
      };
    }, []);
    
    const togglePage = () => {
        setEmail('');
        setPassword('');
        setRePassword('');
        setShowPageA(!showPageA);
        setErrorMessage('');
    };



    const handleGoogleSignup = async () => {
        await loginWithGoogle();
      };

    const handleEmailSignIn = async () => {
      loginWithEmail(email, password);
    }

    const handleCustomSignup = async (e: { preventDefault: () => void; }) => {
    
        if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)) {
          setErrorMessage("Password doesn't meet the requirements.");
          return;
        }

        if (!(password === rePassword)) {
            setErrorMessage("Passwords do not match");
            return;
          }
    
          signUpWithEmail(email, password);
    };

  return (
    <div className="login-container">
      <div className="image-section">
        <img src="/keeper-clear.png" alt="Placeholder" className="left-image" />
      </div>
      {showPageA ? 
      (<div className="form-section">
        <h1>{showPageA}</h1>
        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
        <div className="input-group">
          <input type="email" placeholder="Email Address" className="email-input" value={email} onChange={(e) => setEmail(e.target.value)}/>
          <input type="password" placeholder="Password" className="password-input" value={password} onChange={(e) => setPassword(e.target.value)}/>
        </div>
        {/* <div className="checkbox-forgot">
          <div className="forgot-password">Forgot password?</div>
        </div> */}
        <button className="sign-in-btn" onClick={handleEmailSignIn}>Sign In</button>
        <div className="separator">
          <hr className="separator-line" /> OR <hr className="separator-line" />
        </div>
        <button className="social-btn" onClick={handleGoogleSignup}>Sign in with Google</button>
        <button className="social-btn" onClick={togglePage}>Sign up with Email</button>
      </div>) : (
      <div className="form-section">
        <div className="signUpHeader">
        <p>Sign Up</p>
        {/* <button onClick={togglePage} style={{ marginLeft: '20px', padding: '5px 10px' }}> */}
        <div className="x-mark" onClick={togglePage}></div>
      {/* </button> */}
      </div>
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>} 
      <div className="input-group">
          <input type="email" placeholder="Email Address" className="email-input" value={email} onChange={(e) => setEmail(e.target.value)}/>
          <input type="password" placeholder="Password" className="password-input" value={password} onChange={(e) => setPassword(e.target.value)}/>
          <input type="password" placeholder="Re-Enter Password" className="password-input" value={rePassword} onChange={(e) => setRePassword(e.target.value)}/>
        </div>
        <button className="sign-in-btn" onClick={handleCustomSignup}>Done</button>
      </div>)
      }
    </div>
  );
}

export default Login;
