// Home.js
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { faFilePen} from '@fortawesome/free-solid-svg-icons'
import { faCalendarDays} from '@fortawesome/free-solid-svg-icons'
import { faPeopleGroup} from '@fortawesome/free-solid-svg-icons'
import './Home.css';
import Newsletter from './Newsletter/Newsletter';
import ProfSignUp from './ProfSignUp/ProfSignUp';
import ImageRows from './ImageRows/ImageRows';

function Home() {

  const [selected, setSelected] = useState('Option1');

  const clientCommunityText = "Search for professionals based on hairtype, hairstyles, gender, services provided, and more";
  const clientReviewText = 'Read reviews on efficiency, communication, and comfortability from clients similar to you';
  const clientRequestText = 'Specify the date, hairstyle, budget, and more and let professionals come to you';

  const profCommunityText = "As a platform just for professionals and clients, there's no clutter to compete against";
  const profReviewText = "Share more of yourself and your services and attract clients you'll love";
  const profRequestText = "Look at the date, hairstyle, and budget before filling a request and building up your clientele";

  const taglineText = "Hair care that's sweet \n Community that sticks";

  const [rectangleText1, setRectangleText1] = useState(clientCommunityText);
  const [rectangleText2, setRectangleText2] = useState(clientReviewText);
  const [rectangleText3, setRectangleText3] = useState(clientRequestText);

  const [icon, setIcon] = useState(faFilePen);

  const imagePaths = [
   '/blackWomanBraids.jpg',
   '/asianWomanBarber.jpg',
   '/whiteWomanStylist.jpg',
   '/blackMaleBarber.jpg'
 ];

 const allTags = [
   ["Woman Owned", "Black Owned", "4C", "Twists"],
   ["Thin Hair", "AAPI Owned", "Type 1", "Fade"],
   ["Woman Owned", "Color Services", "2B",  "Queer Centered"],
   ["Black Owned", "Color Services", "4C", "Experienced"]
 ]

 const allTitles = [
   "Jasmine & Key", "Mara & Steven", "Cassie & Jo", "Marcus & Jan"
 ]

const imageRowsTitle = "Search more. Find more.";

  let ComponentToRender;

 if (selected === 'Option1') {
   ComponentToRender = Newsletter;
 } else {
   ComponentToRender = ProfSignUp;
 }



const handleChange = (option) => {
  setSelected(option);
  if (option === 'Option1') {
    setRectangleText1(clientCommunityText);
    setRectangleText2(clientReviewText);
    setRectangleText3(clientRequestText);
    setIcon(faFilePen);
  } else {
    setRectangleText1(profCommunityText);
    setRectangleText2(profReviewText);
    setRectangleText3(profRequestText);
    setIcon(faPeopleGroup);
  }
};

  return (
    <div className="App">
      <header className="header">
      <img src="/hhLogo.png" alt="Honeycomb Logo" className="image-resize" />

      </header>

      <div className="image-separator">
      <h1>We stick talented stylists with sweet clients </h1>
      </div>
      <main>
      <section className="features">
      <div className="feature-block">
      <Newsletter />
      </div>
      <ProfSignUp />
      <div className="feature-block">
      </div>
      </section>
        <section className="intro">
          <div className="selector">
        <div
          className={`selector-item ${selected === 'Option1' ? 'selected' : ''}`}
          onClick={() => handleChange('Option1')}
        >
          For Clients
        </div>
        <div
          className={`selector-item ${selected === 'Option2' ? 'selected' : ''}`}
          onClick={() => handleChange('Option2')}
        >
          For Professionals
        </div>

      </div>
        </section>

        <div className="rectangle-container">
       <div className="rounded-rectangle features-animation">
         <FontAwesomeIcon icon={faMagnifyingGlass} size="3x"/>
         <h2>{selected === 'Option1' ? 'Better Search' : 'Better platform'}</h2>
         <span className="rectangle-text ">{rectangleText1}</span>
       </div>
       <div className="rounded-rectangle features-animation">
         <FontAwesomeIcon icon={icon} size="3x"/>
         <h2>{selected === 'Option1' ? 'Precise reviews' : 'Better Connections'}</h2>
         <span className="rectangle-text features-animation">{rectangleText2}</span>
       </div>
       <div className="rounded-rectangle features-animation">
         <FontAwesomeIcon icon={faCalendarDays} size="3x"/>
         <h2>{selected === 'Option1' ? 'Make Requests' : 'Fulfill Requests'}</h2>
         <span className="rectangle-text features-animation">{rectangleText3}</span>
       </div>
     </div>
     <ImageRows imagePaths={imagePaths} title={imageRowsTitle} allTags={allTags} allTitles={allTitles}/>
      </main>

      <footer className="footer">
        <p>Copyright 2023. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Home;
