import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import LoadingScreen from './LoadingScreen';

type PrivateRouteProps = {
  path: string;
  element: React.ReactElement;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ path, element }) => {
  const { user, loading } = useAuth();
  const location = useLocation();
  
  if (loading) {
    return <Navigate to="/loading" replace state={{ from: location }} />;
  }

  
  return user ? element : <Navigate to="/login" replace state={{ from: location }} />;
};

export default PrivateRoute;
