import React, { useState, useEffect } from 'react';
import { storage } from '../firebase'; // Import your Firebase storage
import { ref, getDownloadURL } from 'firebase/storage';

interface ImageProps {
  firebaseFileLocation: string; // Changed to accept file location
}

const CachedImage: React.FC<ImageProps> = ({ firebaseFileLocation }) => {
  const [imageUrl, setImageUrl] = useState<string>('');

  useEffect(() => {
    // Fetch image URL from Firebase Storage based on file location
    if (!imageUrl) {
      const imageRef = ref(storage, firebaseFileLocation); // Create a reference to the file
      getDownloadURL(imageRef) // Fetch the download URL
        .then((url) => {
          setImageUrl(url); // Set the image URL if successful
        })
        .catch((error) => {
          console.error('Error fetching image from Firebase:', error);
          // Handle any errors here, such as setting a default image
        });
    }
  }, [firebaseFileLocation, imageUrl]);

  return (
    <img
      src={imageUrl}
      alt="From Firebase"
      loading="lazy"
      onError={(e) => (e.currentTarget.src = 'public/favicon.ico')}
    />
  );
};

export default CachedImage;
