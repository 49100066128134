import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './MapComponent.css';

function MapComponent({searchValue, setSearchValue, geopoint, setGeoPoint}) {
    const [isMapKitInitialized, setMapKitInitialized] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const mapRef = useRef(null);

    useEffect(() => {
        const initializeMapKit = async () => {

            function authorizationCallbackFunc(done) {
                // Send an HTTP GET request to your server to obtain a JWT
                fetch(process.env.REACT_APP_MAPKIT_JS_URL)
                  .then(response => response.text())  // Assume the JWT is returned as plain text
                  .then(jwt => {
                    // Pass the JWT to MapKit JS
                    done(jwt);
                  });
              }

            if (!window.mapkit || window.mapkit.loadedLibraries === undefined || window.mapkit.loadedLibraries.length === 0) {
                await new Promise(resolve => { window.initMapKit = resolve });
                delete window.initMapKit;
            }

            window.mapkit.init({
                authorizationCallback: authorizationCallbackFunc
            });

            
        const map = new window.mapkit.Map(mapRef.current, {
            center: new window.mapkit.Coordinate(37.7749, -122.4194), // San Francisco, CA
        });

        map.addEventListener('regionChangeEnd', function(e) {
            if (map.region.center.latitude > 49.384358 || map.region.center.latitude < 24.396308 || 
                map.region.center.longitude > -66.934570 || map.region.center.longitude < -125.001650) {

                map.setCenterAnimated(new window.mapkit.Coordinate(37.7749, -122.4194));
            }
        });

        setMapKitInitialized(true);
        };

        initializeMapKit();
    }, []);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);

        if (isMapKitInitialized && value.length > 0) {
            const usBounds = new window.mapkit.CoordinateRegion(
                new window.mapkit.Coordinate(37.0902, -95.7129), // Approximate center of the U.S.
                new window.mapkit.CoordinateSpan(50, 50)         // Approximate span covering the U.S.
            );
            const search = new window.mapkit.Search({ region: usBounds });
            search.autocomplete(value, (error, data) => {
                if (error) {
                    console.error(error);
                    return;
                }
                const filteredResults = data.results.filter(result => result.displayLines[1] !== "Search Nearby");
                setSearchResults(filteredResults);
            });
        } else {
            setSearchResults([]);
        }
    };

    const handleResultClick = (result) => {
        const { coordinate, displayLines } = result;
        const myRegion = new window.mapkit.CoordinateRegion(
            new window.mapkit.Coordinate(coordinate.latitude, coordinate.longitude),
            new window.mapkit.CoordinateSpan(0.01, 0.01)
        );
        setGeoPoint(coordinate);
        setSearchValue(displayLines);
        setSearchResults([]);
    };

    if (!isMapKitInitialized) {
        return <div>Loading MapKit...</div>;
    }

    return (
        <div className="Map-Field">
            <input
            className="Map-Input"
                type="text"
                value={searchValue}
                onChange={handleSearchChange}
                placeholder="Address"
            />
            <div>
                {searchResults.map(result => (
                    <div
                        key={result.id ? result.id : uuidv4()}
                        onClick={() => handleResultClick(result)}
                    >
                        <b>{result.displayLines[0]}</b> {result.displayLines[1]}
                    </div>
                ))}
            </div>
            <div ref={mapRef} style={{ width: '100%', height: '000px' }}></div>
        </div>
    );
}

export default MapComponent;
