import React, { useState } from 'react';
import './SelectableList.css';

function SelectableList({ items, selectedSet, onToggleItem}) {

    const toggleItem = (item) => {
        if (selectedSet.has(item)) {
            onToggleItem(new Set([...selectedSet].filter(x => x !== item)));
        } else {
            onToggleItem(new Set([...selectedSet, item]));
        }
    };

    return (
        <div className="list-container">
            {items.map((item, index) => (
                <div
                    key={index}
                    className={`list-item ${selectedSet.has(item) ? 'selected' : ''}`}
                    onClick={() => toggleItem(item)}
                >
                    {item}
                </div>
            ))}
        </div>
    );
}

export default SelectableList;
